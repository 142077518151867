import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Layout } from './components/layout/Layout';
import { ContentContainer } from './components/ContentContainer';
import { Error } from './components/generic/Error';
//import { ServerInfo } from './components/generic/ServerInfo';
//import { Preview } from './components/Preview/Preview';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css'


function App() {
    return (
        <div className="App">
            <Layout>
                <Switch>
                    {/*<Route exact path='/'>*/}
                    {/*    <Redirect to={'/abc123/7'} />*/}
                    {/*</Route>                */}
                    <Route exact path='/:token/:user/:folderid?' component={ContentContainer} />
                    <div className="container">
                        <Route component={Error} />
                    </div>
                </Switch>
                <br />
                <ToastContainer />
            </Layout>
        </div>
    );
}

export default App;
