import React, { useCallback, useEffect } from "react";

export const PreviewFileModal = (props) => {
    const { show, fileToPreview, filePath } = props;

    const handleClick = useCallback(
        () => {
            if (show) {
                props.closeCallback(false);
            }
        }, [show]);
    
    useEffect(() => {
        document.addEventListener("click", handleClick);
        return () => {
            document.removeEventListener("click", handleClick);
        };
    });

    function getFileExtension(ofile) {
        let sExtension = '';

        if (ofile?.name) {
            sExtension = ofile?.name?.split('.').pop().toLowerCase();
        }

        return sExtension;
    }

    return (
        <div className="preview" id="previewFile-modal" style={show ? { display: "flex" } : {}}>
            <div className="preview-content" style={getFileExtension(fileToPreview) === "pdf" || getFileExtension(fileToPreview) === "webp" ? { height: "75%", width: "75%" } : {} }>
                <div className="preview-header">
                    <h4>{fileToPreview?.name}</h4>
                </div>
                {fileToPreview?.name
                    ?
                    <embed src={filePath} style={getFileExtension(fileToPreview) === "pdf" || getFileExtension(fileToPreview) === "webp" ? { height: "100%", width: "100%" } : {}} />
                    :
                    <div></div>
                }
            </div>
        </div>
    );

}