import React, { Component } from 'react';
import { Form, Col } from 'react-bootstrap';
import { CgPlayListSearch } from 'react-icons/cg';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import PropTypes from 'prop-types';

import qs from 'qs';

import AppContext from '../shared/context/AppContext';
import URLS from '../shared/constants/URLS';
import FullLogo from '../../assets/images/full_logo.png';
import { UserInformation } from './UserInformation';

import './Header.css';

export class Header extends Component {
    static contextType = AppContext;
    static propTypes = {
        showSearch: PropTypes.bool,
        disableSearch: PropTypes.bool,
        disableAdvancedSearch: PropTypes.bool,
        onSearch: PropTypes.func,
        onAdvancedSearchClick: PropTypes.func,
    }

    constructor(props, context) {
        super(props, context);

        let urlParams = { ...qs.parse("") };

        this.state = {
            showUserInformation: false,
            showMobileSearch: false,
            myDealers: true           
        };
    }

    toggleUserInformation = () => {
        this.setState({ showUserInformation: !this.state.showUserInformation });
    }

    toggleMobileSearch = () => {
        this.setState({ showMobileSearch: !this.state.showMobileSearch });
    }
   
    handleDealerSelect = (option) => {
        this.context.setLoading(true);
        this.context.changeUser(option.value).then(() => {
            this.context.setLoading(false);
        });
    }

    handleDealerToggle = () => {
        this.setState({ myDealers: !this.state.myDealers });
    }


    handleWhoAmIClick = (e) => {
        e.preventDefault();
        window.location = `/${this.context && this.context.applicationUser ? this.context.applicationUser.getCRMID : 0}/serverinfo`;
    }

    handleAdvancedSearchClick = (e) => {
        this.props.onAdvancedSearchClick();
    }

    render() {
        let allowNav = true;
        
        let CompanyName = '';      

        const DEFAULT_URL = allowNav ? `/` : '#';

        return (
            <>
                <div className="header d-block">
                    <div className="container cf">
                        <div className="logo d-flex float-left">
                            <img src={FullLogo} alt="Sandhills Cloud" />
                        </div>
                  
                    </div>
                </div>
               
            </>
        );
    }
}