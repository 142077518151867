import useContextMenu from "./useContextMenu";

const ContextMenu = (props) => {
    const { anchorPoint, show, itemIDRightClicked } = useContextMenu(props.setSelectedIndexes, props.setLastSelectedIndex);

    const supportedFileTypes = ["pdf", "jpe", "jpg", "jpeg", "png", "webp", "gif", "jfif"];
    const dMaxBytes = 5242880; //5MB

    function canPreviewFile(fileToPreview) {
        var bIsValid = false;
        
        if (!fileToPreview.isDir && fileToPreview?.size <= dMaxBytes && supportedFileTypes.some(extension => extension === fileToPreview.name.split('.').pop().toLowerCase())) {
            bIsValid = true;
        }

        return bIsValid;
    }

    function generateContextMenuButtons() {
        var contextMenuButtons = [];
        if (itemIDRightClicked != null && itemIDRightClicked != "") {
            var rightClickedItem = props.getItemByItemID(itemIDRightClicked);

            if (rightClickedItem.isDir) {
                contextMenuButtons.push(<li key="liRenameFolder" className="contextMenuOption" onClick={() => props.displayFolderModal(true, 'Rename', rightClickedItem)}>Rename</li>);
            }

            if (canPreviewFile(rightClickedItem)) {
                contextMenuButtons.push(<li key="liPreview" className="contextMenuOption" onClick={() => props.displayPreviewFileModal(true, rightClickedItem) }>Preview</li>);
            }
            contextMenuButtons.push(<li key="liNewFolder" className="contextMenuOption" onClick={() => props.displayFolderModal(true, 'Create')}>New folder</li>);
            contextMenuButtons.push(<li key="liDelete" className="contextMenuOption" onClick={props.confirmDelete}>Delete</li>);
            contextMenuButtons.push(<li key="liDownload" className="contextMenuOption" onClick={() => props.confirmDownload(false)}>Download</li>);
            contextMenuButtons.push(<hr key="lihr" />);
            contextMenuButtons.push(<li key="liCancel" className="contextMenuOption">Cancel</li>);
        }

        return contextMenuButtons;
    }

    if (show) {
        return (
            <ul className="contextMenu" style={{ top: anchorPoint.y, left: anchorPoint.x }}>
                {generateContextMenuButtons()}
            </ul>
        );
    }
    return <></>;
};

export default ContextMenu;