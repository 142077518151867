import React from "react";

export const ConfirmationModal = (props) => {
    const { show, handleModalResponse } = props;

    function handleClose() {
        props.closeCallback(false);
    }

    function newLineText(bodyText) {
        if (bodyText != null) {
            const text = bodyText;
            const newText = text.split('\n').map((str, index) => <p key={index}>{str}<br /></p>);

            return newText;
        }        
    }

    return (
        <div className="modal" id="confirmation-modal" style={show ? { display: "block" } : {}}>
            <div className="modal-content">
                <div className="modal-head">
                    <h2>{props.confirmationModalHead}</h2>
                    <span className="close" onClick={handleClose}>
                        <i className="fas fa-times" />
                    </span>
                </div>
                <div className="modal-main">
                     {newLineText(props.confirmationModalBody)}
                </div>
                <div className="modal-buttons cf">
                    <>{props.ActionType == 'Upload'
                        ?
                        <div>
                            <button className="close-modal" onClick={() => { handleClose(); handleModalResponse("Overwrite"); }}>Overwrite</button>
                            <button className="close-modal" onClick={() => { handleClose(); handleModalResponse("Skip"); }}>Skip</button>
                            <button className="cancel-btn" onClick={handleClose}>Cancel</button>
                        </div>
                        :
                        <>{props.ActionType == 'DownloadSelected'
                            ?
                            <div>
                                <button className="close-modal" onClick={() => { handleClose(); handleModalResponse("Zip"); }}>Zip Files (recommended)</button>
                                <button className="close-modal" onClick={() => { handleClose(); handleModalResponse("Individual"); }}>Download</button>
                                <button className="cancel-btn" onClick={handleClose}>Cancel</button>
                            </div>
                            :
                            <>{props.ActionType == 'FolderWarning'
                                ?
                                <div>
                                    <button className="close-modal" onClick={() => { handleClose(); handleModalResponse("Individual"); }}>Confirm</button>
                                    <button className="cancel-btn" onClick={handleClose}>Cancel</button>
                                </div>
                                :
                                <div>
                                    <button className="close-modal" onClick={() => { handleClose(); handleModalResponse("AllFiles"); }}>Confirm</button>
                                    <button className="cancel-btn" onClick={handleClose}>Cancel</button>
                                </div>
                            }</>
                        }</>
                    }</>

                </div>
            </div>
        </div>
    );
}