import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import "../../App.css";

export const FolderHierarchy = (props) => {
    const urlParams = useParams();

    const { files, contactsCRMID, setSelectedIndexes, setLastSelectedIndex, dragOverRowID } = props;

    function clearIndexes() {
        setSelectedIndexes([]);
        setLastSelectedIndex(-1);
    }

    function createHierarchy() {
        let divs = [];
        var counter = 0;
        var currentFolderID = urlParams.folderid;

        if (files.length > 0) {
            while (currentFolderID != null) {
                var folder = files.find(x => {
                    return x.id == currentFolderID && x.isDir == true;
                });

                divs.unshift(<Link key={folder.id} data-key={folder.id} to={`/${urlParams.token}/${urlParams.user}/${folder.id}`} onClick={() => clearIndexes([])}>
                    <div className={`folder-h ${dragOverRowID == folder.id ? "dragOver" : ""}`} data-key={folder.id} onDragOver={(e) => props.handleOnDragOver(e)} onDrop={(e) => props.handleOnDrop(e)}>
                        {folder.name}
                    </div>
                </Link>);

                divs.unshift(<i key={"fhi" + counter} className="fas fa-chevron-right"></i>)

                currentFolderID = folder.parentFolderID;
                counter += 1;
            }
        }

        divs.unshift(<Link key={"base"} data-key={"base"} to={`/${urlParams.token}/${urlParams.user}`} onClick={() => clearIndexes([])}>
            <div className={`folder-h ${dragOverRowID == "base" ? "dragOver" : ""}`} data-key={"base"} onDragOver={(e) => props.handleOnDragOver(e)} onDrop={(e) => props.handleOnDrop(e)}>
                {contactsCRMID}
            </div>
        </Link>);
        return divs;
    }


    return (
        <div className="folder-hierarchy">
            {createHierarchy()}
        </div>
    );
}