import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory, useParams, Link } from 'react-router-dom';
import { ConfirmationModal } from '../shared/modals/ConfirmationModal';
import { CreateFolderModal } from '../shared/modals/CreateFolderModal';
import { PreviewFileModal } from '../shared/modals/PreviewFileModal';
import { FolderHierarchy } from './FolderHierarchy';
import ContextMenu from '../shared/context/ContextMenu';
import { getClassNameForExtension } from 'font-awesome-filetypes'; //Icons

import "../../App.css";
import axios from 'axios';


export const FileTable = (props) => {
    const urlParams = useParams();
    const history = useHistory();

    const { files, getFiles, setSortByASC, setSortByHeaderName, sortByASC, sortByHeaderName } = props;
    const [selectedIndexes, setSelectedIndexes] = useState([]);
    const [disableDownloadButton, setDisableDownloadButton] = useState(false);
    const [lastSelectedIndex, setLastSelectedIndex] = useState(-1);

    //Modal
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [confirmationModalHead, setConfirmationModalHead] = useState('');
    const [confirmationModalBody, setConfirmationModalBody] = useState('');
    const [confirmationModalActionType, setConfirmationModalActionType] = useState('');

    const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
    const [folderToRename, setFolderToRename] = useState('');

    const [showPreviewFileModal, setShowPreviewFileModal] = useState(false);
    const [fileToPreview, setFileToPreview] = useState({});
    const [fileToPreviewPath, setFileToPreviewPath] = useState('');

    const [confirmDeleteFile, setConfirmDeleteFile] = useState(false);
    const [confirmDownloadAll, setConfirmDownloadAll] = useState(false)
    const [downloadResponse, setDownloadResponse] = useState('');

    //Drag & Drop
    const dragItem = useRef();
    const dragOverItem = useRef();
    const [dragOverRowID, setDragOverRowID] = useState('');


    const dMaxBytes = 52428800; //50MB
    //const dMaxBytes = 78643200; //75MB
    //const dMaxBytes = 104857600; //100MB

    useEffect(() => {
        if (confirmDeleteFile) {
            handleDeleteFile();
        }
        if (confirmDownloadAll) {
            handleDownloadFile();
        }
    }, [confirmDeleteFile, confirmDownloadAll])


    //Get Item(s) functions
    function getItemByItemID(itemID) {
        return files.find(x => {
            return x.id == itemID;
        });
    }

    function getItemsFromSelectedIndexes(ignoreFolders) {
        let lstFilesToReturn = [];
        let lstIDsToRemove = []

        selectedIndexes.map(index => {
            let oFile = getItemByItemID(index);

            if (oFile != null) {
                if (!oFile.isDir) {
                    lstFilesToReturn.push(oFile);
                } else if (oFile.isDir && ignoreFolders == false) {
                    lstFilesToReturn.push(oFile);
                }
            } else {
                lstIDsToRemove.push(index);
            }
        });

        if (lstIDsToRemove.length > 0) {
            //item was deleted by another user, remove from selected indexes (rare case)
            let freshArray = [];
            selectedIndexes.map(index => {
                if (!lstIDsToRemove.includes(index)) {
                    freshArray.push(index);
                }
            });
            setSelectedIndexes([...freshArray]);
        }
        return lstFilesToReturn;
    }

    function getItemsInFolder(folderID) {
        var lstReturn = [];

        lstReturn = files.filter(x => {
            return (x.isDir && x.parentFolderID == folderID) || (x.isDir == false && x.folderID == folderID);
        });

        return lstReturn;
    }

    function GetLargeFilesFromSelectedFolder(selectedFolder, oReturn = []) {

        //Get all files and folders for the passed in folder
        var childItems = files.filter(x => (x.isDir && x.parentFolderID == selectedFolder.id) || (x.isDir == false && x.folderID == selectedFolder.id))

        childItems.map(child => {
            if (child.isDir == false && child.size > dMaxBytes) {
                oReturn.push(child);
            } else if (child.isDir) {
                GetLargeFilesFromSelectedFolder(child, oReturn);
            }
        });

        return oReturn;
    }




    //Formatting & validation
    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    function itemIsInCurrentDirectory(item) {
        //Validates if the file/folder is in the current directory
        if ((item.isDir == false && item.folderID == urlParams.folderid) || (item.isDir == true && item.parentFolderID == urlParams.folderid)) {
            return true;
        } else {
            return false;
        }
    }

    function isCurrentDirectoryEmpty() {
        return files.some(item => (item.isDir == false && item.folderID == urlParams.folderid) || (item.isDir == true && item.parentFolderID == urlParams.folderid))
    }

    function validateFolderName(sFolderName) {
        var bIsValid = false;
        var sErrorMessage = '';

        if (sFolderName != null && sFolderName != "") {
            if (sFolderName.length <= 50) {
                if (/^[^\s^\x00-\x1f\\?*:"";<>|\/.][^\x00-\x1f\\?*:"";<>|\/]*[^\s^\x00-\x1f\\?*:"";<>|\/.]+$/.test(sFolderName)) {
                    if (!files.some((x) => x.isDir == true && x.parentFolderID == urlParams.folderid && x.name.toLowerCase() == sFolderName.toLowerCase())) {
                        bIsValid = true;
                    } else {
                        sErrorMessage = 'destination already contains a folder with that name.'
                    }
                } else {
                    sErrorMessage = `invalid folder name.`;
                }
            } else {
                sErrorMessage = `the name can't contain more than 50 characters.`;
            }
        }
        return { bIsValid, sErrorMessage };
    }

    function doesSelectedIndexesContainFiles() {
        var bReturn = false;

        for (var i = 0; i < selectedIndexes.length; i++) {
            var oFile = getItemByItemID(selectedIndexes[i])

            if (oFile != null) {
                if (!oFile?.isDir) {
                    bReturn = true;
                    break;
                }
            }
        }
        return bReturn;
    }

    function validateDragAndDrop(itemsToMove, itemsNewLocation) {
        var validItems = [];
        var invalidItemNames = [];
        itemsToMove.map((itemToMove) => {
            if (itemToMove != null) {
                if (itemsNewLocation == 'base') {
                    //Can't move to the base if it's already there
                    if ((itemToMove.isDir && itemToMove.parentFolderID != null) || (itemToMove.isDir == false && itemToMove.folderID != null)) {
                        //Can't move a folder to the base if a folder with the same name already exists there
                        if (!getItemsInFolder(null).some((x) => x.name.toLowerCase() == itemToMove.name.toLowerCase())) {
                            validItems.push(itemToMove);
                        } else {
                            invalidItemNames.push(itemToMove.name);
                        }
                    }
                } else {
                    //Can't move items into a file
                    if (itemsNewLocation.isDir) {
                        //Can't drop a folder into itself
                        if (itemToMove.folderID != itemsNewLocation.folderID) {
                            //Can't move a file/folder to the location it's already at
                            if ((itemToMove.isDir && itemToMove.parentFolderID != itemsNewLocation.folderID) || (itemToMove.isDir == false && itemToMove.folderID != itemsNewLocation.folderID)) {
                                //Can't move a folder to a location that already has a folder with the same name
                                if (!getItemsInFolder(itemsNewLocation.folderID).some((x) => x.name.toLowerCase() == itemToMove.name.toLowerCase())) {
                                    validItems.push(itemToMove);
                                } else {
                                    invalidItemNames.push(itemToMove.name);
                                }
                            }
                        }
                    }
                }
            }
        });

        return { validItems, invalidItemNames };
    }

    function verifySelectedIndexes() {
        let lstIDsToRemove = []

        selectedIndexes.map(index => {
            let oFile = getItemByItemID(index);

            if (oFile == null) {
                lstIDsToRemove.push(index);
            }
        });

        if (lstIDsToRemove.length > 0) {
            //item was deleted by another user, remove from selected indexes (rare case)
            let freshArray = [];
            selectedIndexes.map(index => {
                if (!lstIDsToRemove.includes(index)) {
                    freshArray.push(index);
                }
            });
            setSelectedIndexes([...freshArray]);
        }
        return selectedIndexes.length;
    }




    //Selecting & Sorting
    function handleCheckBoxChange(e, index) {
        if (index != 'All') {
            if (e.target.checked == true) {
                setSelectedIndexes([...selectedIndexes].concat(index));
            } else {
                let freshArray = selectedIndexes.filter(val => val != index);
                setSelectedIndexes([...freshArray]);
            }
        } else {
            if (e.target.checked == true) {
                let tempArray = [];
                files.map((file) => {
                    if (itemIsInCurrentDirectory(file)) {
                        tempArray.push(file.id);
                    }
                });
                setSelectedIndexes(tempArray);
            } else {
                setSelectedIndexes([]);
            }
        }

    }

    function handleSelectTableRow(e, file) {
        if (e.detail == 2 && !e.target.id?.includes('chkBox') && file.isDir) {
            setSelectedIndexes([]);
            setLastSelectedIndex(-1);
            history.push(`/${urlParams.token}/${urlParams.user}/${file.id}`);
        } else if (e.detail == 2 && !e.target.id?.includes('chkBox') && !file.isDir) {
            const downloadURL = `api/${urlParams.token}/${urlParams.user}/${urlParams.folderid}/file/DownloadFile`;

            //let the browser do everything
            var link = document.createElement("a");
            link.download = file.name;
            link.href = `${downloadURL}?fileName=${encodeURIComponent(file.name)}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            fetch(`/api/${urlParams.token}/${urlParams.user}/${urlParams.folderid}/file/MarkFileAsDownloaded?fileName=${encodeURIComponent(file.name)}`)
                .then(response => {
                    getFiles(urlParams.token, urlParams.user, urlParams.folderid);
                });            

        } else if (e.target.className != 'mobileFolder') {
            if (!e.shiftKey) {
                setLastSelectedIndex(Number(e.target.getAttribute("data-index")));
                var specificCheckBox = document.getElementById("chkBox" + file.id);
                
                if (specificCheckBox != null) {
                    if (specificCheckBox.checked == true) {
                        let freshArray = selectedIndexes.filter(val => val != file.id);
                        setSelectedIndexes([...freshArray]);
                        specificCheckBox.checked = false;
                    } else {
                        if (!selectedIndexes.includes(file.id)) {
                            setSelectedIndexes([...selectedIndexes].concat(file.id));
                        }
                        specificCheckBox.checked = true;
                    }
                }
            } else {
                var currentSelectedIndex = Number(e.target.getAttribute("data-index"));

                if (lastSelectedIndex != -1) {
                    if (currentSelectedIndex > lastSelectedIndex) {
                        let newArray = [...selectedIndexes];
                        for (let i = lastSelectedIndex; i <= currentSelectedIndex; i++) {
                            let row = document.getElementById('fr' + i);

                            if (row != null) {
                                let rowsDataKey = row.getAttribute("data-key");

                                if (!newArray.includes(rowsDataKey)) {
                                    newArray.push(rowsDataKey);
                                    let chkbox = document.getElementById("chkBox" + rowsDataKey);
                                    chkbox.checked = true;
                                }
                            }
                        }
                        setSelectedIndexes([...newArray]);
                    } else {
                        let newArray = [...selectedIndexes];
                        for (let i = currentSelectedIndex; i <= lastSelectedIndex; i++) {
                            let row = document.getElementById('fr' + i);

                            if (row != null) {
                                let rowsDataKey = row.getAttribute("data-key");

                                if (!newArray.includes(rowsDataKey)) {
                                    newArray.push(rowsDataKey);
                                    let chkbox = document.getElementById("chkBox" + rowsDataKey);
                                    chkbox.checked = true;
                                }
                            }
                        }
                        setSelectedIndexes([...newArray]);
                    }
                }
            }
        }
    }

    function SortTableBy(headerName) {
        setLastSelectedIndex(-1);
        if (sortByHeaderName != headerName) {
            setSortByHeaderName(headerName);
            setSortByASC(true);
        } else {
            if (sortByASC === null) {
                setSortByASC(true);
            } else if (sortByASC == true) {
                setSortByASC(false);
            } else if (sortByASC == false) {
                setSortByASC(true);
            }
        }
    }




    //Drag & Drop
    function handleOnDragStart(e, file) {
        setLastSelectedIndex(-1);
        //Check to see if the drag object is contained within the list of selected indexes.
        if (selectedIndexes.some(x => x === file.id)) {
            dragItem.current = getItemsFromSelectedIndexes(false);
        } else {
            setSelectedIndexes([]);
            //Need to set the single file/folder to an array
            var newArray = [];
            newArray.push(file);
            dragItem.current = newArray;
            var newSIArray = [];
            newSIArray.push(file.id);
            setSelectedIndexes(newSIArray);
        }
    }

    function handleOnDragOver(e) {
        if (dragItem.current != null) {
            e.preventDefault();

            var folderID = e.target.getAttribute("data-key");

            if (folderID != "base") {
                var dragEnterFolder = getItemByItemID(folderID);
                //set the dragOverItem
                setDragOverRowID(dragEnterFolder.id);
                dragOverItem.current = dragEnterFolder;
            } else {
                setDragOverRowID("base");
                dragOverItem.current = "base";
            }
        }

    }

    async function handleOnDrop(e) {
        e.preventDefault();
        var itemsNewLocation = dragOverItem.current;

        if (dragItem.current != null && itemsNewLocation != null) {
            //Validate
            var { validItems, invalidItemNames } = validateDragAndDrop(dragItem.current, itemsNewLocation);

            if (validItems.length >= 1) {
                var newFolderID = null;
                if (itemsNewLocation != 'base') {
                    newFolderID = itemsNewLocation.folderID;
                }

                //Need to handle the "Invalid Date" values
                var newItemsToMove = validItems.map((item) => {
                    if (item.downloadedOnDate == 'Invalid Date') {
                        item.downloadedOnDate = null;
                    }

                    if (item.uploadedOnDate == 'Invalid Date') {
                        item.uploadedOnDate = null;
                    }

                    return item;
                });

                props.setSpinnerText('Moving Files...')
                props.setEnableSpinnerOverlay(true);

                const data = new FormData();
                data.append('itemsToMove', JSON.stringify(newItemsToMove));
                data.append('newFolderID', newFolderID);

                await axios.post(`/api/${urlParams.token}/${urlParams.user}/${urlParams.folderid}/file/MoveFileOrFolders`, data)
                    .then((response) => {
                        if (response.data.isSuccess == false) {
                            toast.error(response.data.errorMessage);
                            getFiles(urlParams.token, urlParams.user, urlParams.folderid); //Refresh the files and clear slected indexes
                            setSelectedIndexes([]);
                        } else {
                            getFiles(urlParams.token, urlParams.user, urlParams.folderid);
                            setSelectedIndexes([]);
                        }
                    })
                    .catch((e) => {
                        toast.error("Error occurred when moving items");
                    });

                props.setEnableSpinnerOverlay(false);
                props.setSpinnerText('');
            }

            //Display warnings
            if (invalidItemNames.length >= 1) {
                toast.warn(`${invalidItemNames.length == 1 ? `The folder "${invalidItemNames[0]}" already exists` : `${invalidItemNames.length} of the selected folders already exist`} at the destination.`);
            }
        }


        dragItem.current = null;
        dragOverItem.current = null;
        setDragOverRowID('');
    }




    //Modal Logic
    //Confirmation Modal
    function displayConfirmModal(Display, ActionType) {
        var sHeader = ``;
        var sBody = ``;
        if (Display == true) {
            if (ActionType.toLowerCase() == 'delete') {
                //let numFilesString = selectedIndexes.length == 1 ? 'the selected item' : selectedIndexes.length != files.filter(x => itemIsInCurrentDirectory(x)).length ? `the ${selectedIndexes.length} selected items` : 'all items';
                let numFilesString = selectedIndexes.length == 1 ? 'the selected item' : `the ${selectedIndexes.length} selected items`;
                sHeader = `Delete`;
                sBody = `Are you sure you want to delete ${numFilesString}?`;

            } else if (ActionType.toLowerCase() == 'downloadall') {
                sHeader = 'Download All Items';
                sBody = `Are you sure you want to download all Items?`;

                if (files.filter(x => !x.isDir).some(file => file.size == null || file.size > dMaxBytes)) {
                    sBody += `\n\nThe following files are too large and will be downloaded individually:`;

                    files.map(x => {
                        if (!x.isDir && (x.size == null || x.size > dMaxBytes)) {
                            sBody += `\n${x.name}`;
                        }
                    });
                }

            } else if (ActionType.toLowerCase() == 'downloadselected') {
                let lstFilesToDownload = getItemsFromSelectedIndexes(false);
                let bAppendFileNames = false;
                let lstLargeFiles = [];

                sHeader = `Download Items`;
                sBody = ``;

                //See if any large files were selected
                if (lstFilesToDownload.some(file => !file.isDir && (file.size == null || file.size > dMaxBytes))) {
                    bAppendFileNames = true;
                }

                //See if any folders with large files were selected
                if (lstFilesToDownload.some(file => file.isDir)) {
                    lstFilesToDownload.filter(file => file.isDir).map(file => {
                        GetLargeFilesFromSelectedFolder(file).map(file => {
                            lstLargeFiles.push(file);
                        });
                    });
                }

                if (bAppendFileNames || lstLargeFiles.length > 0) {
                    sBody += `The following files are too large and will be downloaded individually:`;

                    lstFilesToDownload.map(file => {
                        if (!file.isDir && (file.size == null || file.size > dMaxBytes)) {
                            sBody += `\n${file.name}`;
                        }
                    });

                    lstLargeFiles.map(file => {
                        sBody += `\n${file.name}`;
                    });
                }

            } else if (ActionType.toLowerCase() == 'folderwarning') {
                let folderToDownload = getItemsFromSelectedIndexes(false);
                let largeFolderFiles = GetLargeFilesFromSelectedFolder(folderToDownload[0]);

                sHeader = `Folder Warning`;
                sBody = `The folder '${folderToDownload[0].name}' contains ${largeFolderFiles.length == 1 ? `a file ` : `files `} too large and will be downloaded individually:`;

                largeFolderFiles.map(file => {
                    sBody += `\n${file.name}`;
                });
            }

            setConfirmationModalHead(sHeader);
            setConfirmationModalBody(sBody);
            setConfirmationModalActionType(ActionType);
            setShowConfirmationModal(true);

        } else {
            setConfirmationModalHead('');
            setConfirmationModalBody('');
            setShowConfirmationModal(false);
        }
    }

    function handleConfirmationModalResponse(response) {
        if (confirmationModalActionType.toLowerCase() == 'delete') {
            setConfirmDeleteFile(true);
            setConfirmationModalActionType('');
        } else if (confirmationModalActionType.toLowerCase() == 'downloadall' || confirmationModalActionType.toLowerCase() == 'downloadselected' || confirmationModalActionType.toLowerCase() == 'folderwarning') {
            setDownloadResponse(response);
            setConfirmDownloadAll(true);
            setConfirmationModalActionType('');
        }
    }

    //Folder Modal
    function displayFolderModal(Display, ActionType, folderToRename) {
        if (Display == false) {
            setShowCreateFolderModal(false);
        } else {
            setShowCreateFolderModal(true);
            setConfirmationModalActionType(ActionType);
            setFolderToRename(folderToRename);
            var folderTextBox = document.getElementById('createFolderNameInput');
            if (folderTextBox != null) {
                if (ActionType == 'Rename') {
                    folderTextBox.value = folderToRename.name;
                    setTimeout(function () { folderTextBox.select() }, 20);
                } else {
                    setTimeout(function () { folderTextBox.focus() }, 20);
                }

            }
        }
    }
    //Preview Modal
    async function displayPreviewFileModal(Display, fileToPreview) {
        if (Display == false) {
            setShowPreviewFileModal(false);
            setFileToPreviewPath('');
            setFileToPreview({});
            getFiles(urlParams.token, urlParams.user, urlParams.folderid);
        } else {
            await fetch(`/api/${urlParams.token}/${urlParams.user}/${urlParams.folderid}/file/GetPreviewFilePath?sFileID=${fileToPreview.id}`)
                .then(response => response.json())
                .then(data => {
                    if (data.isSuccess) {
                        setFileToPreviewPath(data.data);
                        setFileToPreview(fileToPreview);
                        setShowPreviewFileModal(true);
                    } else {
                        toast.error(data.errorMessage);
                    }
                })
                .catch(() => {
                    toast.error('Failed to preview file.');
                });
        }
    }




    

    //Download Logic
    function confirmDownload(downloadAll) {
        if (downloadAll == true) {
            displayConfirmModal(true, 'DownloadAll');
        } else {
            var selectedItems = getItemsFromSelectedIndexes(false);
            if (selectedItems.length == 1) {
                if (selectedItems[0].isDir) {
                    if (GetLargeFilesFromSelectedFolder(selectedItems[0]).length > 0) {
                        displayConfirmModal(true, 'FolderWarning')
                    } else {
                        handleDownloadFile();
                    }
                } else {
                    handleDownloadFile();
                }
            } else if (selectedItems.length > 1) {
                displayConfirmModal(true, 'DownloadSelected');
            }
        }
    }

    async function handleDownloadFile() {
        setDisableDownloadButton(true);
        setConfirmDownloadAll(false);

        props.setSpinnerText('Preparing Download...');
        props.setEnableSpinnerOverlay(true);

        const downloadURL = `api/${urlParams.token}/${urlParams.user}/${urlParams.folderid}/file/DownloadFile`;

        if (downloadResponse == '' || downloadResponse == null || downloadResponse.toLowerCase() == 'individual') {
            let lstFilesToDownload = getItemsFromSelectedIndexes(false);

            if (lstFilesToDownload.length > 0) {
                //if a folder was selected to download, need to look to see if it contains any large files and add them to the lstFilesToDownload
                if (lstFilesToDownload.some(item => item.isDir)) {
                    lstFilesToDownload.filter(item => item.isDir).map(folder => {
                        GetLargeFilesFromSelectedFolder(folder).map(folder => {
                            lstFilesToDownload.push(folder);
                        });
                    });
                }

                for (let i = 0; i < lstFilesToDownload.length; i += 1) {
                    setTimeout(
                        async () => {
                            if (lstFilesToDownload[i].isDir == false) {
                                //let the browser do everything
                                var link = document.createElement("a");
                                link.download = lstFilesToDownload[i].name;
                                link.href = `${downloadURL}?fileName=${encodeURIComponent(lstFilesToDownload[i].name)}`;
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);

                                await fetch(`/api/${urlParams.token}/${urlParams.user}/${urlParams.folderid}/file/MarkFileAsDownloaded?fileName=${encodeURIComponent(lstFilesToDownload[i].name)}`)
                                    .then(response => {
                                        getFiles(urlParams.token, urlParams.user, urlParams.folderid);

                                        if (!lstFilesToDownload.some(file => file.isDir)) {
                                            props.setEnableSpinnerOverlay(false);
                                            props.setSpinnerText('');
                                        }
                                    });
                            } else {
                                //Need to handle the "Invalid Date" values
                                if (lstFilesToDownload[i].downloadedOnDate == 'Invalid Date') {
                                    lstFilesToDownload[i].downloadedOnDate = null;
                                }

                                if (lstFilesToDownload[i].uploadedOnDate == 'Invalid Date') {
                                    lstFilesToDownload[i].uploadedOnDate = null;
                                }

                                var data = new FormData();
                                data.append('sFolder', JSON.stringify(lstFilesToDownload[i]));

                                await axios.post(`/api/${urlParams.token}/${urlParams.user}/${urlParams.folderid}/file/ZipFolder`, data)
                                    .then((response) => {
                                        if (response.data.isSuccess) {
                                            //let the browser do everything
                                            var link = document.createElement("a");
                                            link.download = lstFilesToDownload[i].name;
                                            link.href = downloadURL + "?fileName=" + response.data.data + "&folderName=" + encodeURIComponent(lstFilesToDownload[i].name);
                                            document.body.appendChild(link);
                                            link.click();
                                            document.body.removeChild(link);

                                            getFiles(urlParams.token, urlParams.user, urlParams.folderid);
                                            props.setEnableSpinnerOverlay(false);
                                            props.setSpinnerText('');
                                        } else {
                                            toast.error(`Failed to download folder: ${lstFilesToDownload[i].name}`);
                                            props.setEnableSpinnerOverlay(false);
                                            props.setSpinnerText('');
                                        }
                                    })
                                    .catch(() => {
                                        toast.error('Failed to download folder.');
                                        props.setEnableSpinnerOverlay(false);
                                        props.setSpinnerText('');
                                    });
                            }                            
                        },
                        i * 200
                    );
                }
            }
        } else if (downloadResponse.toLowerCase() == 'zip' || downloadResponse.toLowerCase() == 'allfiles') {
            let lstFilesToZip = [];
            let lstFilesStraightDownload = [];
            let bDownloadAll = false;

            if (downloadResponse.toLowerCase() == 'zip') {
                getItemsFromSelectedIndexes(false).map(file => {
                    //Need to handle the "Invalid Date" values
                    if (file.downloadedOnDate == 'Invalid Date') {
                        file.downloadedOnDate = null;
                    }
                    if (file.uploadedOnDate == 'Invalid Date') {
                        file.uploadedOnDate = null;
                    }

                    if (file.isDir) {
                        //If a directory is selected, need to see if it contains files too large
                        GetLargeFilesFromSelectedFolder(file).map(file => {
                            lstFilesStraightDownload.push(file);
                        });

                        lstFilesToZip.push(file);
                    } else if (!file.isDir && file.size != null && file.size <= dMaxBytes) {
                        lstFilesToZip.push(file);
                    } else if (!file.isDir && (file.size == null || file.size > dMaxBytes)) {
                        lstFilesStraightDownload.push(file);
                    }
                });
            } else if (downloadResponse.toLowerCase() == 'allfiles') {
                bDownloadAll = true;
                let bStopZipFileSearch = false;
                files.map(file => {
                    //If downloading all files, we only need to pull the ones too large to zip. Otherwise, gettings all the files to zip is handled in the backend
                    if (!file.isDir && (file.size == null || file.size > dMaxBytes)) {
                        lstFilesStraightDownload.push(file);
                    } else if (!bStopZipFileSearch) {
                        lstFilesToZip.push(file);
                        bStopZipFileSearch = true; //Only need one object in the array to hit the logic to zip the files
                    }
                });
            }

            if (lstFilesToZip.length > 0) {
                let ZipFileName = props.contactsCRMID + "-Files" + new Date().getTime() + ".zip";

                var data = new FormData();
                data.append('sFiles', JSON.stringify(lstFilesToZip));
                data.append('sZipFileName', ZipFileName);
                data.append('bDownloadAll', bDownloadAll);

                await axios.post(`/api/${urlParams.token}/${urlParams.user}/${urlParams.folderid}/file/CreateAllFilesZip`, data)
                    .then((response) => {
                        if (response.data.isSuccess == true) {
                            //let the browser do everything
                            let link = document.createElement("a");
                            link.download = ZipFileName;
                            link.href = downloadURL + "?fileName=" + ZipFileName;
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);

                            props.setEnableSpinnerOverlay(false);
                            props.setSpinnerText('');
                        } else {
                            toast.error('Download Failed. Try downloading the files individually.');
                            props.setEnableSpinnerOverlay(false);
                            props.setSpinnerText('');
                        }
                    })
                    .catch((e) => {
                        toast.error('Download Failed. Some files may be too large. Try downloading the files individually.');
                        props.setEnableSpinnerOverlay(false);
                        props.setSpinnerText('');
                    });
            }

            if (lstFilesStraightDownload.length > 0) {
                for (let i = 0; i < lstFilesStraightDownload.length; i += 1) {
                    setTimeout(
                        async () => {
                            //let the browser do everything
                            let link = document.createElement("a");
                            link.download = lstFilesStraightDownload[i].name;
                            link.href = downloadURL + "?fileName=" + encodeURIComponent(lstFilesStraightDownload[i].name);
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);

                            await fetch(`/api/${urlParams.token}/${urlParams.user}/${urlParams.folderid}/file/MarkFileAsDownloaded?fileName=${encodeURIComponent(lstFilesStraightDownload[i].name)}`)
                                .then(response => {
                                    getFiles(urlParams.token, urlParams.user, urlParams.folderid);
                                });
                        },
                        i * 200
                    );
                }
            }
        }

        setDisableDownloadButton(false);
        setDownloadResponse('');
        setLastSelectedIndex(-1);
        getFiles(urlParams.token, urlParams.user, urlParams.folderid);
    }




    //Delete Logic
    function confirmDelete() {
        if (getItemsFromSelectedIndexes(false).length > 0) {
            displayConfirmModal(true, 'Delete')
        } else {
            toast.warn("Select files to delete");
        }
    }

    function handleDeleteFile() {
        setConfirmDeleteFile(false);

        let lstFilesToDelete = [];
        let lstFoldersToDelete = [];

        getItemsFromSelectedIndexes(false).map(file => {
            if (file.isDir) {
                lstFoldersToDelete.push(file.id);
            } else {
                lstFilesToDelete.push(file.id);
            }
        });

        if (lstFilesToDelete.length > 0) {
            fetch(`/api/${urlParams.token}/${urlParams.user}/${urlParams.folderid}/file/DeactivateUploadedFiles?sFileIDs=${lstFilesToDelete}`)
                .then(response => response.json())
                .then(data => {
                    if (data.isSuccess) {
                        getFiles(urlParams.token, urlParams.user, urlParams.folderid);
                        setSelectedIndexes([]);
                    } else {
                        //toast.warn(data.errorMessage);
                        getFiles(urlParams.token, urlParams.user, urlParams.folderid);
                    }
                })
                .catch(() => {
                    toast.error('Error occurred when deleting the file.');
                });
        }

        if (lstFoldersToDelete.length > 0) {
            fetch(`/api/${urlParams.token}/${urlParams.user}/${urlParams.folderid}/file/DeleteVirtualFolder?sFolderIDs=${lstFoldersToDelete}`)
                .then(response => response.json())
                .then(data => {
                    if (data.isSuccess) {
                        getFiles(urlParams.token, urlParams.user, urlParams.folderid);
                        setSelectedIndexes([]);
                    } else {
                        //toast.warn(data.errorMessage);
                        getFiles(urlParams.token, urlParams.user, urlParams.folderid);
                    }
                })
                .catch(() => {
                    toast.error('Error occurred when deleting the folder.');
                });
        }

        setLastSelectedIndex(-1);
    }




    //Folder Create/Rename Logic
    function handleCreateFolder(sNewFolderName, sActionType, folderToRename) {
        //Validate folder name
        var { bIsValid, sErrorMessage } = validateFolderName(sNewFolderName);
        if (bIsValid) {
            if (sActionType == 'Create') {
                fetch(`/api/${urlParams.token}/${urlParams.user}/${urlParams.folderid}/file/AddVirtualFolder?FolderName=${encodeURIComponent(sNewFolderName)}`)
                    .then(response => response.json())
                    .then(data => {
                        if (data.isSuccess) {
                            getFiles(urlParams.token, urlParams.user, urlParams.folderid);
                        } else {
                            toast.error(data.errorMessage);
                            getFiles(urlParams.token, urlParams.user, urlParams.folderid); //Refresh the files
                        }
                    });
            } else if (sActionType == 'Rename') {
                fetch(`/api/${urlParams.token}/${urlParams.user}/${urlParams.folderid}/file/RenameVirtualFolder?FolderIDToUpdate=${folderToRename.id}&newFolderName=${encodeURIComponent(sNewFolderName)}`)
                    .then(response => response.json())
                    .then(data => {
                        if (data.isSuccess) {
                            getFiles(urlParams.token, urlParams.user, urlParams.folderid);
                        } else {
                            toast.error('An error occurred when renaming the folder.');
                            getFiles(urlParams.token, urlParams.user, urlParams.folderid); //Refresh the files
                        }
                    });
            }
        } else if (!bIsValid && sErrorMessage != ``) {
            toast.warn(`Failed to ${sActionType.toLowerCase()} folder: ${sErrorMessage}`);
        }

        setConfirmationModalActionType('');
        setLastSelectedIndex(-1);
        setFolderToRename('');
    }



    return (
        <div className="org-chart-list">
            <div className="table-top">
                {props.contactsURL
                    ?
                    <h2><a href={props.contactsURL} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>{props.contactsInfo}</a></h2>
                    :
                    <h2>{props.contactsInfo}</h2>
                }
            </div>
            <div className="table-container">
                <ContextMenu getItemByItemID={getItemByItemID} setSelectedIndexes={setSelectedIndexes} setLastSelectedIndex={setLastSelectedIndex} displayFolderModal={displayFolderModal} confirmDelete={confirmDelete} confirmDownload={confirmDownload} displayPreviewFileModal={displayPreviewFileModal} />
                <ConfirmationModal show={showConfirmationModal} closeCallback={displayConfirmModal} confirmationModalHead={confirmationModalHead} confirmationModalBody={confirmationModalBody} handleModalResponse={handleConfirmationModalResponse} ActionType={confirmationModalActionType} />
                <CreateFolderModal show={showCreateFolderModal} closeCallback={displayFolderModal} handleModalResponse={handleCreateFolder} ActionType={confirmationModalActionType} folderToRename={folderToRename} />
                <PreviewFileModal show={showPreviewFileModal} closeCallback={displayPreviewFileModal} fileToPreview={fileToPreview} filePath={fileToPreviewPath} />
                <FolderHierarchy files={files} contactsCRMID={props.contactsCRMID} setSelectedIndexes={setSelectedIndexes} setLastSelectedIndex={setLastSelectedIndex} handleOnDragOver={handleOnDragOver} handleOnDrop={handleOnDrop} dragOverRowID={dragOverRowID} />
                <div className="simple-table-actions" style={{ display: "block" }}>
                    <div className="simple-table-action-btns">
                        <button id="addFolderBtn" className="addFolder-btn" onClick={() => displayFolderModal(true, "Create")}>
                            <i className="fa fa-plus"></i> New Folder
                        </button>
                        <button id="deleteBtn" className="delete-btn" onClick={confirmDelete} style={{ display: selectedIndexes?.length > 0 ? "" : "none" }}>
                            <i className="fa fa-trash"></i> <p id="deleteBtnText">Delete</p> ({getItemsFromSelectedIndexes(false).length})
                        </button>
                        <button id="downloadBtn" className="download-btn" onClick={() => confirmDownload(false)} disabled={disableDownloadButton} style={{ display: selectedIndexes?.length > 0 ? "" : "none" }}>
                            <i className="fa fa-download"></i> <p id="downloadBtnText">Download</p> ({getItemsFromSelectedIndexes(false).length})
                        </button>
                        <button id="downloadAllBtn" className="download-btn" onClick={() => confirmDownload(true)} disabled={disableDownloadButton} style={{ display: files.length > 1 ? "" : "none" }}>
                            <i className="fa fa-download"></i>All Files
                        </button>
                    </div>
                </div>
                <div className="simple-table" >
                    <div className="simple-table-row simple-table-header">
                        <div className="simple-table-check" >
                            <input type="checkbox" checked={files.length == 0 || files.filter(x => itemIsInCurrentDirectory(x)).length == 0 ? false : verifySelectedIndexes() === files.filter(x => itemIsInCurrentDirectory(x)).length ? true : false} onChange={(e) => handleCheckBoxChange(e, 'All')} />
                        </div>
                        <div className={"simple-table-column simple-table-columnhead " + (sortByHeaderName == "name" && sortByASC != null ? "sort-active " + (sortByASC ? "" : "rotate") : "")} onClick={() => SortTableBy("name")}>Name</div>
                        <div className={"simple-table-column simple-table-columnhead " + (sortByHeaderName == "size" && sortByASC != null ? "sort-active " + (sortByASC ? "" : "rotate") : "")} onClick={() => SortTableBy("size")}>Size</div>
                        <div className={"simple-table-column simple-table-columnhead " + (sortByHeaderName == "uploaded" && sortByASC != null ? "sort-active " + (sortByASC ? "" : "rotate") : "")} onClick={() => SortTableBy("uploaded")}>Uploaded</div>
                        <div className={"simple-table-column simple-table-columnhead " + (sortByHeaderName == "downloaded" && sortByASC != null ? "sort-active " + (sortByASC ? "" : "rotate") : "")} onClick={() => SortTableBy("downloaded")}>Last Accessed</div>
                    </div>
                    <>{isCurrentDirectoryEmpty()
                        ?
                        (files.map((file, index) => {
                            if (itemIsInCurrentDirectory(file)) {
                                return (
                                    <div
                                        id={'fr' + index}
                                        key={file.id}
                                        data-key={file.id}
                                        data-index={index}
                                        className={`simple-table-row file-row ${selectedIndexes.some(item => item === file.id) ? "selected" : ""} ${dragOverRowID == file.id ? "dragOver" : ""}`}
                                        onClick={(e) => handleSelectTableRow(e, file)}
                                        onDragOver={(e) => handleOnDragOver(e)}
                                        onDrop={(e) => handleOnDrop(e)}>
                                        <div className="simple-table-check" data-key={file.id} data-index={index}>
                                            <input type="checkbox" id={"chkBox" + file.id} checked={selectedIndexes.some(item => item === file.id)} onChange={(e) => handleSelectTableRow(e, file)} data-key={file.id} data-index={index} />
                                        </div>
                                        <>{file.isDir
                                            ?
                                            <div className="simple-table-column" title="Name" data-key={file.id} data-index={index} draggable onDragStart={(e) => handleOnDragStart(e, file)}>
                                                <i className="fas fa-folder" data-key={file.id} data-index={index}></i>
                                                <div className="pcFolder" data-key={file.id} data-index={index}>
                                                    {file.name}
                                                </div>
                                                <Link className="mobileFolder" data-key={file.id} data-index={index} onClick={() => setSelectedIndexes([])} to={`/${urlParams.token}/${urlParams.user}/${file.id}`}>
                                                    {file.name}
                                                </Link>
                                            </div>
                                            :
                                            <div className="simple-table-column" title="Name" data-key={file.id} data-index={index} draggable onDragStart={(e) => handleOnDragStart(e, file)}>
                                                <i className={"fas " + getClassNameForExtension(file.name.split('.').pop())} data-key={file.id} data-index={index}></i>{file.name}
                                            </div>
                                        }</>
                                        <div className="simple-table-column" title="Size" data-key={file.id} data-index={index}>{file.size != null ? formatBytes(file.size, 1) : "-"}</div>
                                        <div className="simple-table-column" title="Uploaded" data-key={file.id} data-index={index}>{file.uploadedOnDate != "Invalid Date" ? file.uploadedOnDate : "-"}</div>
                                        <div className="simple-table-column" title="Last Accessed" data-key={file.id} data-index={index}>{file.downloadedOnDate != "Invalid Date" ? file.downloadedOnDate : "-"}</div>
                                    </div>
                                )
                            }
                        }))
                        :
                        (<div className="empty-directory-row">
                            <div>This folder is empty.</div>
                        </div>)
                    }</>
                </div>
                <div className="num-files-selected">
                    ({selectedIndexes?.length} of {files.filter(x => itemIsInCurrentDirectory(x)).length} Selected)
                </div>
            </div >
        </div>
    );
}