import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
//import Cookies from 'universal-cookie';

import AppContext from '../shared/context/AppContext';
import { Header } from './Header';

import 'react-toastify/dist/ReactToastify.css';

/**
 * Layout container for the app. Contains nav bar, current user information, and main content container. 
 * This component is also responsible for the loading state.
 * @augments {Component<Props, State>}
 */
//const cookies = new Cookies();
export class Layout extends Component {
    static contextType = AppContext;
    static propTypes = {
        user: PropTypes.object,
        onUserChange: PropTypes.func
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            //isNavOpen: cookies.get('telematicsNavBarStatus') === 'true' || false,
            isLoading: false,
            loadingMessage: '',
            errors: [],
            contextValue: {
                ...props.user,                
                urlParams: props.urlParams,
                fetch: this.fetch,
                fetchFile: this.fetchFile,
                downloadFile: this.downLoadFile,
                setLoading: this.setLoading,
                setError: this.setError,
                toggleNav: this.toggleNav,
                changeUser: props.onUserChange,
                navigate: props.onNavigate,
            },
            showAdvancedSearchModal: false
        }

        let ua = window.navigator.userAgent;
        let msie = ua.indexOf('MSIE ');
        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./))
            toast.warn('It appears you are using an old or outdated browser. Some features of this site may not look or perform as intended. For the best experience, switch to a modern browser such as Google Chrome, Firefox, or Microsoft Edge.', {
                autoClose: false
            });
    }

    /**
     * Performs a fetch with the given parameters. Handles any error messages and parses the response. Also checks for the existence and value of a 'success' field.
     * @param {string} url URL to request
     * @param {boolean} post Flag to determine if POST should be used, GET if false
     * @param {object} input Object to pass as an input if a POST request
     * @param {boolean} showLoading Flag to determine if the page should be loading
     * @param {string} message Loading message that will be show to the user
     */
    fetch = (url, input = null, showLoading = true, message = null) => {
        return new Promise((resolve) => {
            if (showLoading)
                this.setLoading(true, message);

            //Used for error reporting
            let endpoint = url.split('/').pop().split('?')[0];


            let config = {};
            if (input) {
                config = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(input)
                }
            }

            fetch(url, config).then((response) => {
                if (showLoading)
                    this.setLoading(false);
                if (response.ok && !response.redirected) {
                    response.json().then((data) => {
                        if (data.success !== undefined && !data.success) {
                            this.setError(data.message);
                            resolve(null);
                        } else {
                            resolve(data);
                        }
                    }).catch((err) => {
                        //this.setError(this.state.contextValue.translations['sandhills.errorparsingresponsefrom'].replace('!##!', endpoint));
                        this.setError("Error parsing response from !##!.".replace('!##!', endpoint));
                        resolve(null);
                    });
                } else {
                    //this.setError(this.state.contextValue.translations['sandhills.requestfailedorredirected'].replace('!##!', endpoint));
                    this.setError("The request to !##! either failed or got redirected.".replace('!##!', endpoint));
                    resolve(null);
                }
            }).catch((err) => {
                this.setLoading(false);
                //this.setError(this.state.contextValue.translations['sandhills.errorwithrequestto'].replace('!##!', endpoint));
                this.setError("There was an error with the request to !##!.".replace('!##!', endpoint));
                resolve(null);
            });
        });
    }

    fetchFile = (url, input = null) => {
        let config = {};
        if (input) {
            config = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(input)
            };
        }

        return fetch(url, config).then((result) => {
            if (result.ok) {
                return result.blob();
            } else {
                //this.state.contextValue.setError(this.state.contextValue.translations['sandhills.filedownloadresponseerror']);
                this.setError("Error downloading file.");
                return Promise.resolve(null);
            }
        }).catch((err) => {
            //this.state.contextValue.setError(this.state.contextValue.translations['sandhills.filedownloadresponseerror']);
            this.setError("Error downloading file.");
            return Promise.resolve(null);
        });
    }

    /**
     * Sets app-wide loading state
     * @param {boolean} value Boolean that indicates whether or not to display loading indication
     * @param {string} message Optional message to display under the loading indicator
     */
    setLoading = (value, message) => {
        this.setState({ isLoading: value, loadingMessage: message });
    }

    /**
     * Appends new errors to the current list. Not suitable for use in loops (or any fast successive calls)
     * @param {string | string[]} args The errors to append to the list. Can be strings, or an array of strings.
     */
    setError = async (...args) => {
        if (Array.isArray(args)) {
            args.forEach(err => {
                if (Array.isArray(err)) {
                    toast.error(err.join(" "), { autoClose: false });
                } else {
                    toast.error(err, { autoClose: false });
                }
            });
        } else {
            toast.error(args, { autoClose: false });
        }
    }

   



    render() {
        return (
            <AppContext.Provider value={this.state.contextValue}>
                <Row className='mx-0 h-100'>
                    {/*<Col className='border-right p-2 nav-container'>*/}
                    <Header {...this.props}  />
                    {/*</Col>*/}
                    <Col className='main-container' style={{ display: this.state.isLoading ? 'none' : 'block' }}>
                        {/*{this.props.children}*/}
                        {
                            React.Children.map(this.props.children, (child) =>
                                React.cloneElement(child, {                                                                 
                                   
                                })
                            )
                        }
                    </Col>                    
                </Row>
            </AppContext.Provider>
        );
    }
}