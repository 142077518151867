const LoadingSpinnerStyles = {
    spinner: (base) => ({
        ...base,
        width: '120px',
        '& svg circle': {
            stroke: 'rgba(0, 255, 255, 0.5)'
        }
    }),
    content: (base) => ({
        ...base,
        marginTop: '20%'
    })
}

export default LoadingSpinnerStyles;