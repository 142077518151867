import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FileTable } from './FileTable/FileTable'
import { FileUploader } from './FileUploader/FileUploader';
import { Alert } from 'react-bootstrap'
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';

import '../App.css';
import { SideMenu } from './FileTable/SideMenu';

import LoadingOverlay from 'react-loading-overlay';
import LoadingSpinnerStyles from "./shared/LoadingSpinnerStyles";


export const ContentContainer = (props) => {
    const urlParams = useParams();

    //Get Files
    const [files, setFiles] = useState([]);
    const [validToken, setValidToken] = useState(true);
    const [contactsInfo, setContactsInfo] = useState();
    const [contactsURL, setContactsURL] = useState();
    const [contactsCRMID, setContactsCRMID] = useState();

    //Loading Spinner
    const [enableSpinnerOverlay, setEnableSpinnerOverlay] = useState(false);
    const [spinnerText, setSpinnerText] = useState('');

    //Sorting
    const [sortByHeaderName, setSortByHeaderName] = useState("name");
    const [sortByASC, setSortByASC] = useState(null);


    useEffect(() => {
        getFiles(urlParams.token, urlParams.user, urlParams.folderid);
    }, [sortByHeaderName, sortByASC])

    function getFiles(token, user, folderid) {
        const result = axios.get(`api/${token}/${user}/${folderid}/file/GetFiles`).then((result) => {
            if (result.data.validToken) {
                setFiles(result.data.files
                    .sort((a, b) => {
                        if (sortByHeaderName == "name") {
                            if (sortByASC == true) {
                                return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
                            } else if (sortByASC == false) {
                                return a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 1;
                            }
                        } else if (sortByHeaderName == "size") {
                            if (sortByASC == true) {
                                return a.size - b.size;
                            } else if (sortByASC == false) {
                                return b.size - a.size;
                            }
                        } else if (sortByHeaderName == "uploaded") {
                            if (sortByASC == true) {
                                return a.uploadedOnDate?.localeCompare(b.uploadedOnDate);
                            } else if (sortByASC == false) {
                                return b.uploadedOnDate?.localeCompare(a.uploadedOnDate);
                            }
                        } else if (sortByHeaderName == "downloaded") {
                            if (sortByASC == true) {
                                return a.downloadedOnDate?.localeCompare(b.downloadedOnDate);
                            } else if (sortByASC == false) {
                                return b.downloadedOnDate?.localeCompare(a.downloadedOnDate);
                            }
                        }
                    })
                    .sort((a, b) => {
                        if (sortByASC == true) {
                            return Number(b.isDir) - Number(a.isDir);
                        } else if (sortByASC == false) {
                            return Number(a.isDir) - Number(b.isDir);
                        }
                    })
                    .map((f) => {
                        return ({
                            ...f, uploadedOnDate: new Date(f.uploadedOnDate + 'Z').toLocaleString(), downloadedOnDate: new Date(f.downloadedOnDate + 'Z').toLocaleString()
                        });
                    }));
                setValidToken(result.data.validToken);
                setContactsInfo(result.data.contactsInfo);
                setContactsURL(result.data.contactsURL);
                setContactsCRMID(result.data.contactsCRMID);
            } else {
                setValidToken(false);
            }
        });

        return result;
    }

    return (
        <div className="container">
            {!validToken
                ?
                (<div>
                    <br />
                    <Alert variant={"danger"}>
                        Invalid or expired link, please ask your business representative for a new link.
                    </Alert>
                </div>)
                :
                <>{!files
                    ?
                    (<div>Fetching files</div>)
                    :
                    (<LoadingOverlay active={enableSpinnerOverlay} spinner classNamePrefix='FileDrop_Overlay_' styles={LoadingSpinnerStyles} text={spinnerText} >
                        <div className="ContentContainer">
                            <div className="main" style={{ width: "100%" }}>
                                <div className="wrap">
                                    <div className="module">
                                        {/*<div className="search-form">*/}
                                        {/*    <input type="text" placeholder="Search" />*/}
                                        {/*</div>*/}
                                        <div className="tree-container">
                                            {/*<SideMenu />*/}
                                            <FileTable
                                                files={files}
                                                getFiles={getFiles}
                                                contactsInfo={contactsInfo}
                                                contactsURL={contactsURL}
                                                contactsCRMID={contactsCRMID}
                                                setEnableSpinnerOverlay={setEnableSpinnerOverlay}
                                                setSpinnerText={setSpinnerText}
                                                sortByHeaderName={sortByHeaderName}
                                                sortByASC={sortByASC}
                                                setSortByHeaderName={setSortByHeaderName}
                                                setSortByASC={setSortByASC}
                                            />
                                        </div>
                                        <FileUploader token={urlParams.token} user={urlParams.user} folderid={urlParams.folderid} contactsInfo={contactsInfo} files={files} getFiles={getFiles} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </LoadingOverlay>)
                }</>
            }
        </div>
    );
}