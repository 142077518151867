import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import AsyncSelect from 'react-select/async';

import AppContext from '../shared/context/AppContext';

import URLS from '../shared/constants/URLS';

import './UserInformation.css';

const cookies = new Cookies();
export class UserInformation extends Component {
    static contextType = AppContext;

    static propTypes = {

    }

    constructor(props) {
        super(props);

        this.state = {
            myDealers: true
        };
    }

    fetchDealers = (input, callback) => {
        if ((input && input.length > 2) || ((input === null || input === '') && this.state.myDealers)) {
            let inputs = {
                term: input,
                myDealers: this.state.myDealers
            };

            fetch('api/SearchDealers', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(inputs)
            }).then(response => {
                if (response.ok) {
                    response.json().then(data => {
                        if (data && data.length > 0) {
                            let dealers = data.map(x => { return { label: x.text, value: x.value }; });
                            callback(dealers);
                        }
                        callback([]);
                    });
                } else {
                    this.context.setError('Failed to load dealers');
                    callback([]);
                }
            }).catch(error => {
                this.context.setError('Error loading dealers.');
                callback([]);
            });
        } else {
            callback([]);
        }
    };

    handleDealerSelect = (option) => {
        this.context.setLoading(true);
        this.context.changeUser(option.value).then(() => {
            this.context.setLoading(false);
        });
    }

    handleDealerToggle = () => {
        this.setState({ myDealers: !this.state.myDealers });
    }

    handleLogoutClick = (e) => {
        e.preventDefault();
        cookies.remove('ImpersonatedCRMID');
        cookies.remove('CoreLoginSecAuth');
        cookies.remove('LoginSecAuth');
        window.location = this.context.logoutURL;
    }

    handleWhoAmIMouseDown = (e) => {
        e.preventDefault();

        let URL = `/${this.context.applicationUser.getCRMID}${URLS.SERVERINFO}`;

        if (e.button === 0 && !e.ctrlKey) { //left click
            this.context.navigate(URL);
        }
        else if (e.button === 1 || (e.button === 0 && e.ctrlKey)) { //middle click or ctrl-click
            window.open(URLS.BASENAME + URL);
        }
    }

    render() {
        return (
            <div className="UserInfoWrapper cf">
                <div className="UserLogin">
                    <span style={{ display: this.context && this.context.applicationUser && this.context.applicationUser.isImpersonating ? 'block' : 'none' }}>Impersonating: <strong>{this.context && this.context.applicationUser ? this.context.applicationUser.impersonatingParentAccountName : ''}</strong><br /></span>
                    <span>You are logged in as: <strong>{this.context && this.context.applicationUser ? this.context.applicationUser.adUserName : ''}</strong></span>
                    <div className="LogoutButtonContainer">
                        <div onClick={this.handleLogoutClick}>Log Out</div>
                        <div style={{ display: this.context && this.context.applicationUser && this.context.applicationUser.isEmployee ? 'inline-block' : 'none' }} onMouseDown={this.handleWhoAmIMouseDown}>Who Am I</div>
                    </div>
                </div>
                <div className="DealerSelectContainer" style={{ display: this.context && this.context.applicationUser && this.context.applicationUser.isEmployee ? 'inline-block' : 'none' }}>
                    <div className="toggle-buttons">
                        <span className="toggle-bg">
                            <input type="radio" name="toggle" id="my-dealers" defaultChecked onChange={this.handleDealerToggle} />
                            <label htmlFor="my-dealers" className="label-1">My Dealers</label>
                            <input type="radio" name="toggle" id="all-dealers" onChange={this.handleDealerToggle} />
                            <label htmlFor="all-dealers" className="label-2">All Dealers</label>
                            <span className="toggle-switch"></span>
                        </span>
                    </div>

                    <AsyncSelect
                        classNamePrefix='autocomplete'
                        loadOptions={this.fetchDealers}
                        isSearchable
                        placeholder={this.context && this.context.applicationUser && this.context.applicationUser.isImpersonating ? this.context.selectedDealerText : 'Search Dealers'}
                        onChange={this.handleDealerSelect}
                        styles={{
                            dropdownIndicator: () => {
                                return { display: 'none' };
                            },
                            indicatorSeparator: () => {
                                return { display: 'none' };
                            },
                        }}
                    />
                </div>
            </div>
        );
    }
}