import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { toast } from 'react-toastify';
import { ProgressBar, Form, Spinner } from 'react-bootstrap'
import { v4 as uuidv4 } from 'uuid';
import { ConfirmationModal } from '../shared/modals/ConfirmationModal';
import './style.css';
import { each } from 'jquery';


const chunkSize = 1024 * 1024 * 24; //24 MB match this in the controller (appsettings json)

export const FileUploader = (props) => {
    const [showProgress, setShowProgress] = useState(false);
    const [showFinalizing, setShowFinalizing] = useState(false);
    const [counter, setCounter] = useState(1);
    const [fileToBeUpload, setFileToBeUpload] = useState({});
    const [beginingOfTheChunk, setBeginingOfTheChunk] = useState(0);
    const [endOfTheChunk, setEndOfTheChunk] = useState(chunkSize);
    const [progress, setProgress] = useState(0);
    const [fileGuid, setFileGuid] = useState("");
    const [fileName, setFileName] = useState("");
    const [fileSize, setFileSize] = useState(0);
    const [chunkCount, setChunkCount] = useState(0);

    const { files } = props;

    //Confirmation Modal
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [confirmationModalHead, setConfirmationModalHead] = useState('');
    const [confirmationModalBody, setConfirmationModalBody] = useState('');
    const [confirmationModalActionType, setConfirmationModalActionType] = useState('');

    const [newFilesToUpload, setNewFilesToUpload] = useState([]);
    const [existingFilesToUpload, setExistingFilesToUpload] = useState([]);
    const [filesToUpload, setFilesToUpload] = useState([]);
    const [confirmUpload, setConfirmUpload] = useState(false);

    useEffect(() => {
        if (fileSize > 0) {
            fileUpload(counter);
        }
        if (confirmUpload) {
            getFileContext(filesToUpload);
        }
    }, [fileToBeUpload, progress, confirmUpload])

    function resetUploadProperties() {
        setChunkCount(0);
        setShowProgress(false);
        setShowFinalizing(false);
        setCounter(1);
        setFileToBeUpload({});
        setBeginingOfTheChunk(0);
        setEndOfTheChunk(chunkSize);
        setProgress(0);
        setFileGuid("");
        setFileName("");
        setFileSize(0);

        var downloadBtnElement = document.getElementById("downloadBtn");
        var downloadAllBtnElement = document.getElementById("downloadAllBtn");
        var deleteBtnElement = document.getElementById("deleteBtn");

        if (downloadBtnElement != null && deleteBtnElement != null && downloadAllBtnElement != null) {
            downloadBtnElement.removeAttribute("disabled");
            downloadAllBtnElement.removeAttribute("disabled");
            deleteBtnElement.removeAttribute("disabled");
        }
    }

    function displayConfirmModal(Display, ActionType, existingFiles) {
        if (Display == true) {
            let sHeader = '';
            let sBody = '';
            if (existingFiles.length == 1) {
                sHeader = 'Upload File';
                sBody = `The file "${existingFiles[0].fileName}" already exists. Are you sure you want to overwrite the file?`;
            } else {
                sHeader = 'Upload Files';
                sBody = `There are ${existingFiles.length} files with the same names. Are you sure you want to overwrite the files?`;
            }

            setConfirmationModalHead(sHeader);
            setConfirmationModalBody(sBody);
            setConfirmationModalActionType(ActionType);
            setShowConfirmationModal(true);

        } else {
            setConfirmationModalHead('');
            setConfirmationModalBody('');
            setConfirmationModalActionType('');
            setNewFilesToUpload([]);
            setExistingFilesToUpload([]);
            setShowConfirmationModal(false);
        }
    }

    function handleConfirmationModalResponse(response) {
        if (response.toLowerCase() == 'overwrite') {
            //User chose to overwrite all files, so add all files to the list
            setFilesToUpload([...existingFilesToUpload].concat([...newFilesToUpload]));
            setConfirmUpload(true);
        } else if (response.toLowerCase() == 'skip') {
            //Only uplaod new files, skip existing files
            if (newFilesToUpload.length > 0) {
                setFilesToUpload([...newFilesToUpload]);
                setConfirmUpload(true);
            }
        }
    }

    const confirmFileUpload = (e) => {
        var sanitize = require("sanitize-filename");

        if (e.target.files.length > 1) {
            var newFiles = [];
            var existingFiles = [];

            for (let i = 0; i < e.target.files.length; i++) {
                e.target.files[i].fileName = sanitize(e.target.files[i].name);
                if (!isExistingFile(e.target.files[i])) {
                    newFiles.push(e.target.files[i]);
                } else {
                    existingFiles.push(e.target.files[i]);
                }
            }

            if (existingFiles.length > 0) {
                setExistingFilesToUpload(existingFiles);
                setNewFilesToUpload(newFiles);
                displayConfirmModal(true, 'Upload', existingFiles);
            } else {
                getFileContext(newFiles);
            }

        } else if (e.target.files.length == 1) {
            e.target.files[0].fileName = sanitize(e.target.files[0].name);

            if (!isExistingFile(e.target.files[0])) {
                getFileContext(e.target.files[0]);
            } else {
                let existingFile = [];
                existingFile.push(e.target.files[0]);
                setExistingFilesToUpload(existingFile);
                displayConfirmModal(true, 'Upload', existingFile);
            }
        }
        e.target.value = null;
    }

    const getFileContext = (files) => {
        setShowFinalizing(false);
        setConfirmUpload(false);

        if (files.length > 1) {
            resetChunkProperties();
            const data = new FormData();

            for (let i = 0; i < files.length; i++) {
                data.append('file', files[i]);
            }
            data.append('contactsinfo', props.contactsInfo);

            axios.post(`/api/${props.token}/${props.user}/${props.folderid}/file/MultiUpload`, data)
                .then((response) => {
                    if (response.data.success == false) {
                        toast.error(response.data.message);
                        setTimeout(() => { window.location.reload(); }, 5000);
                    } else {
                        toast.success('Upload Success');
                        //window.location.reload(); /*need to combine these componenets or something to reset the state.files here instead*/
                        setTimeout(() => { props.getFiles(props.token, props.user, props.folderid); }, 1000);
                        resetUploadProperties();
                    }
                })
                .catch((e) => {
                    toast.error('Please upload larger files individually or as a zip')
                    setTimeout(() => { window.location.reload(); }, 5000);
                });

        } else {
            let _file;
            if (files.length == 1) {
                _file = files[0];
            } else {
                _file = files;
            }
            if (_file.size > 0) {
                resetChunkProperties();
                setFileSize(_file.size)

                const _totalCount = _file.size % chunkSize == 0 ? _file.size / chunkSize : Math.floor(_file.size / chunkSize) + 1; // Total count of chunks will have been upload to finish the file
                setChunkCount(_totalCount);

                setFileToBeUpload(_file)
                const _fileID = uuidv4() + "." + _file.fileName.split('.').pop();
                setFileGuid(_fileID)
                setFileName(_file.fileName)
            } else {
                toast.error('Cannot upload empty files');
            }
        }

    }

    const fileUpload = () => {
        setCounter(counter + 1);
        if (counter <= chunkCount) {
            var chunk = fileToBeUpload.slice(beginingOfTheChunk, endOfTheChunk);
            uploadChunk(chunk)
        }
    }

    const uploadChunk = async (chunk) => {
        try {
            const response = await axios.post(`/api/${props.token}/${props.user}/${props.folderid}/file/UploadChunks`, chunk, {
                params: {
                    id: counter,
                    fileName: fileGuid,
                },
                headers: { 'Content-Type': 'application/octet-stream' }
            });
            const data = response.data;
            if (data.isSuccess) {
                setBeginingOfTheChunk(endOfTheChunk);
                setEndOfTheChunk(endOfTheChunk + chunkSize);
                if (counter == chunkCount) {
                    setProgress(100);
                    setShowProgress(false);
                    await uploadCompleted();
                } else {
                    var percentage = (counter / chunkCount) * 100;
                    setProgress(percentage);
                }
            } else {
                toast.error('Error Occurred:' + data.errorMessage)
                console.log('Error Occurred:', data.errorMessage);
            }

        } catch (error) {
            debugger
            console.log('error', error)
        }
    }

    const uploadCompleted = async () => {
        setShowFinalizing(true);
        var formData = new FormData();
        formData.append('fileGuid', fileGuid);
        formData.append('fileName', fileName);
        formData.append('contactsinfo', props.contactsInfo);

        const response = await axios.post(`/api/${props.token}/${props.user}/${props.folderid}/file/UploadComplete`, {}, {
            params: {
                fileGuid, fileGuid,
                fileName: fileName,
                chunkCount: chunkCount,
                contactsInfo: props.contactsInfo,
                fileSize: fileSize
            },
            data: formData,
        });

        const data = response.data;
        if (data.isSuccess) {
            //setTimeout(() => { window.location.reload(); }, 1000);
            setTimeout(() => { props.getFiles(props.token, props.user, props.folderid); }, 1000);
            resetUploadProperties();
        } else {
            toast.error('Error Occurred:' + data.errorMessage)
            console.log('Error Occurred:', data.errorMessage);
        }
    }

    const resetChunkProperties = () => {
        setShowProgress(true);
        setProgress(0);
        setCounter(1);
        setBeginingOfTheChunk(0);
        setEndOfTheChunk(chunkSize);
        var downloadBtnElement = document.getElementById("downloadBtn");
        var downloadAllBtnElement = document.getElementById("downloadAllBtn");
        var deleteBtnElement = document.getElementById("deleteBtn");

        if (downloadBtnElement != null && deleteBtnElement != null && downloadAllBtnElement != null) {
            downloadBtnElement.setAttribute("disabled", "disabled");
            downloadAllBtnElement.setAttribute("disabled", "disabled");
            deleteBtnElement.setAttribute("disabled", "disabled");
        }
        
    }

    const isExistingFile = (fileToUpload) => {
        if (!files.some(file => file.name.toLowerCase() == fileToUpload.fileName.toLowerCase())) {
            return false;
        }
        else {
            return true;
        }
    }


    return (
        <div>
            <ConfirmationModal show={showConfirmationModal} closeCallback={displayConfirmModal} confirmationModalHead={confirmationModalHead} confirmationModalBody={confirmationModalBody} handleModalResponse={handleConfirmationModalResponse} ActionType={confirmationModalActionType} />
            <form>
                <br />
                <Form.Group style={{ display: showProgress ? "block" : "none" }}>
                    Uploading {fileName}... <Spinner animation="border" size="sm" />
                    <ProgressBar animated now={progress} label={`${progress.toFixed(0)}%`} />
                </Form.Group>
                <div className="finalizing" style={{ display: showFinalizing ? "block" : "none" }} >File has completed upload and will be available after processing </div>
                <div className="form-group fileUploader" style={{ display: showProgress || showFinalizing ? "none" : "block" }}>
                    <label>Select a file to upload. Loose files are best uploaded as a .zip </label>
                    <input id="fileInput"
                        type="file"
                        onChange={confirmFileUpload}
                        className="form-control"
                        multiple />

                </div>

                <button style={{ display: "none" }} >Upload</button>
            </form>
        </div>
    )
};