import React, { Component } from 'react';
import { Card } from 'react-bootstrap';


export class Error extends Component {
    render() {
        return (
            <div className="main">
                <br />
                <Card className='p-3 boxshadow'>
                    <h3>Access Denied</h3>
                    <h3>You are not authorized to view this page. </h3>
                    <p>To learn more, contact your business representative. </p>
                </Card>
            </div>
        );
    }
}
