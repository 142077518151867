import { useEffect, useCallback, useState } from "react";

const useContextMenu = (setSelectedIndexes, setLastSelectedIndex) => {
    const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
    const [show, setShow] = useState(false);
    const [itemIDRightClicked, setItemIDRightClicked] = useState('');


    function checkRightClickedRowIsSelected(rightClickedRowID, rightClickedRowIndex) {
        setLastSelectedIndex(rightClickedRowIndex);
        var lstSelectedRows = document.getElementsByClassName('selected');
        var freshArray = [];

        if (lstSelectedRows.length > 0) {
            var bRightClickedRowWasSelected = false;
            for (var i = 0; i < lstSelectedRows.length; i++) {
                if (lstSelectedRows[i].id == `fr${rightClickedRowIndex}`) {
                    bRightClickedRowWasSelected = true;
                    break;
                }
            }

            if (bRightClickedRowWasSelected == false) {
                freshArray.push(rightClickedRowID);
                setSelectedIndexes([...freshArray]);
            }

        } else {
            freshArray.push(rightClickedRowID);
            setSelectedIndexes([...freshArray]);
        }
    }

    function disableScroll() {
        document.body.classList.add("stop-scrolling");
    }

    function enableScroll() {
        document.body.classList.remove("stop-scrolling");
    }

    const handleContextMenu = useCallback(
        (event) => {
            setItemIDRightClicked('');
            var targetsDataKey = event.target.getAttribute("data-key");
            var targetsIndexKey = event.target.getAttribute("data-index");

            //Only start logic if a folder/file was selected
            if (targetsDataKey != null && targetsDataKey != 'base' && !event.srcElement.classList.contains('folder-h')) {
                event.preventDefault();
                checkRightClickedRowIsSelected(targetsDataKey, targetsIndexKey);
                disableScroll(event);
                setAnchorPoint({ x: event.clientX, y: event.clientY });
                setShow(true);
                setItemIDRightClicked(targetsDataKey)
            } else {
                if (event.srcElement.classList.contains('contextMenu') || event.srcElement.classList.contains('contextMenuOption')) {
                    event.preventDefault();
                }
                setShow(false);
            }
        },
        [setShow, setAnchorPoint, setItemIDRightClicked]
    );

    const handleClick = useCallback(
        () => {
            if (show) {
                setShow(false);
                enableScroll();
            }
        }, [show]);

    useEffect(() => {
        document.addEventListener("click", handleClick);
        document.addEventListener('drag', handleClick);
        document.addEventListener("contextmenu", handleContextMenu);
        return () => {
            document.removeEventListener("click", handleClick);
            document.addEventListener('drag', handleClick);
            document.removeEventListener("contextmenu", handleContextMenu);
        };
    });
    return { anchorPoint, show, itemIDRightClicked };
};

export default useContextMenu;