import React from "react";

export const CreateFolderModal = (props) => {
    const { show, handleModalResponse } = props;
    const [textInput, setTextInput] = React.useState('');

    function handleClose() {
        setTextInput('');
        document.getElementById("createFolderNameInput").value = '';
        props.closeCallback(false);
    }

    function handleChange(event) {
        setTextInput(event.target.value);
    }

    function handleKeyDown(e) {
        if (e.key === 'Enter') {
            handleModalResponse(textInput, props.ActionType, props.folderToRename);
            handleClose();
        } else if (e.key === 'Escape') {
            handleClose();
        }
    }

    return (
        <div className="modal" id="createFolder-modal" style={show ? { display: "block" } : {}}>
            <div className="modal-content" style={{ maxWidth: 350 }} >
                <div className="modal-head">
                    <h2>{props.ActionType == 'Create' ? 'New' : 'Rename'} Folder</h2>
                    <span className="close" onClick={handleClose}>
                        <i className="fas fa-times" />
                    </span>
                </div>
                <div className="modal-main" style={{alignSelf:"center"} }>
                    <input id="createFolderNameInput" type="text" maxLength="50" onChange={handleChange} onKeyDown={(e) => { handleKeyDown(e) }} />
                </div>
                <div className="modal-buttons cf">
                    <button className="close-modal" onClick={() => { handleModalResponse(textInput, props.ActionType, props.folderToRename); handleClose(); }}>{props.ActionType}</button>
                    <button className="cancel-btn" onClick={handleClose}>Cancel</button>
                </div>
            </div>
        </div>
    );
}